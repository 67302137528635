import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

class Intro extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        navigate: false,
        isLoading: false
    }
    
    // componentDidMount() {
    //     setTimeout(() => {
    //         this.setState({ isLoading: false });
    //     }, 2000);
    // }


    handleSubmit = event => {
        event.preventDefault();
        this.setState({ navigate : true});
    };
    
    handleChange = lang => {
        this.props.handleLangaugeSubmit(lang);
    }

    render() {

        const { isLoading } = this.state;

        return (
            <>
            
                { this.state.navigate && <Navigate to="/category" replace={true} /> }

                {
                    isLoading ? 
                    <div className='intro'>
                            <div className="head">
                                <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#8a4628" >
                                    <Skeleton height={150} width={150} circle={true} containerClassName="flex-1" />
                                </SkeletonTheme>
                            </div>

                            <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#8a4628" >
                                <Skeleton height={200} containerClassName="flex-1" />
                            </SkeletonTheme>

                            <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#8a4628" >
                                <Skeleton height={200} containerClassName="flex-1" />
                            </SkeletonTheme>
                        
                    </div>
                    : 
                    <div className='intro' style={ { backgroundImage :`url(${this.settings.introCover})`, backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat' } } >
                    <div className="head">
                        <img src={this.settings.logo} alt="" />
                        <h3>{this.settings.name}</h3>
                    </div>
                    <form onSubmit={this.handleSubmit} >
                        <button type='submit' onClick={() => this.handleChange('sw')}> Svenska </button>
                        <button type='submit' onClick={() => this.handleChange('en')}> English </button>
                        <button type='submit' onClick={() => this.handleChange('ar')}> العربیة </button>
                        <button type='submit' onClick={() => this.handleChange('krd')} > کوردی </button>
                    </form>

                    <div className="info">

                        <h3>Contact Us</h3>
                        
                        { 
                            this.settings.facebook 
                            &&
                            <div className='info-item'><a href={`https://www.facebook.com/${this.settings.facebook}`}><i className="fa-brands fa-facebook"></i><span>{this.settings.facebook}</span></a></div>
                        }

                        { 
                            this.settings.instgram 
                            &&
                            <div className='info-item'><a href={`https://www.instagram.com/${this.settings.instgram}`}><i className="fa-brands fa-instagram"></i> <span>{this.settings.instgram}</span></a></div>
                        }

                        { 
                            this.settings.tiktok 
                            &&
                            <div className='info-item'><a href={`https://www.tiktok.com/${this.settings.tiktok}`}><i className="fa-brands fa-tiktok"></i> <span>{this.settings.tiktok}</span></a></div>
                        }

                        { 
                            this.settings.email 
                            &&
                            <div className='info-item'><a href={`https://www.instagram.com/${this.settings.email}`}> <i className="fa-solid fa-envelope"></i> <span>{this.settings.email}</span></a></div>
                        }
                        
                        { 
                            this.settings.snapchat 
                            &&
                            <div className='info-item'><a href={`https://www.snapchat.com/add/${this.settings.snapchat}`}><i className="fa-brands fa-snapchat"></i> <span>{this.settings.snapchat}</span></a></div>
                        }

                        { 
                            this.settings.phone 
                            &&
                            <div className='info-item'><a href={`tel:${this.settings.phone}`}><i className="fa-solid fa-phone-volume"></i> <span>{this.settings.phone}</span></a></div>
                        }

                        { 
                            this.settings.phone2
                            &&
                            <div className='info-item'><a href={`tel:${this.settings.phone2}`}><i className="fa-brands fa-whatsapp"></i> <span>{this.settings.phone2}</span></a></div>
                        }

                        { 
                            this.settings.address 
                            &&
                            <div className='info-item'><a href={this.settings.address}><i className="fa-solid fa-location-dot"></i> <span>{this.settings.address}</span></a></div>
                        }
                        
                    </div>

                    <div className='time'>
                        <h2><i className="fa-solid fa-clock"></i></h2>
                        <p>
                        Öppettider :Restaurang <br/>
                        Måndag - Fredag 11:00-20:00 <br/>
                        Lördag - Söndag 12:00-20:00
                        </p>

                        <p>
                        Öppettider: Shisha Bar <br/>
                        Måndag-Torsdag 17:00-24:00 <br/>
                        Fredag-Lördag 17:00-02:00 <br/>
                        Söndag-16:00-21:00
                        </p>


                    </div>

                    <div className='send-feedback'>
                        <Link to={"/feedback"}>
                            <i class="fa-solid fa-message"></i> Feedback 
                        </Link>
                    </div>

                </div>

                }

               
            </>
        );
    }
}

export default Intro;